<app-header-redesign>
  <app-back-button (aaaClick)="onBack()" slot="start" />
  <app-title i18n>Vehicle Model</app-title>
</app-header-redesign>

<ion-content>
  <ion-grid class="ion-margin ion-no-padding">
    <aaa-text
      i18n
      size="subheadline"
      weight="regular"
      color="subtle"
      i18n-aria-label
      class="ion-margin-bottom"
      aria-label="Select your vehicle's model"
      role="heading"
      aria-level="1"
      aria-live="polite">
      Select your vehicle's model
    </aaa-text>

    <app-vehicle-header-step></app-vehicle-header-step>

    <ion-searchbar
      id="searchbar"
      type="text"
      class="custom ion-margin-top"
      [maxlength]="maxChars"
      (ionInput)="onSearch($event)">
    </ion-searchbar>
  </ion-grid>

  <app-alphabet-scroll-list
    [isLoading]="(isLoadingVehicleModelTypes$ | async) || (isLoading$ | async)"
    [items]="filteredModels$ | async"
    [selected]="selectedModel.value"
    [isHideAlphabet]="!!searchInput"
    (itemSelected)="modelChange($event)">
  </app-alphabet-scroll-list>

  <aaa-modal
    i18n-title
    [title]="'Model Details'"
    i18n-subtitle
    [subtitle]="'Required'"
    [isOpen]="isOpen"
    (aaaModalDidDismiss)="handleDismissAWDModal()"
    [initialBreakpoint]="1"
    [breakpoints]="[0, 1]"
    contentMode="fit"
  >
    <aaa-text class="ion-margin-top" i18n>I have a 4WD or AWD Vehicle:</aaa-text>
    <div class="checkbox-container ion-margin-bottom ion-margin-top">
      <ion-list>
        <ion-item>
          <drr-checkbox
            name="awd-yes"
            (change)="driveTrainChange($event, true)"
            [checked]="isAWD === true"
          >
            <aaa-text color="dark" i18n>Yes</aaa-text>
          </drr-checkbox>
        </ion-item>
        <ion-item>
          <drr-checkbox
            name="awd-no"
            (change)="driveTrainChange($event, false)"
            [checked]="isAWD === false"
          >
            <aaa-text color="dark" i18n>No</aaa-text>
          </drr-checkbox>
        </ion-item>
      </ion-list>
    </div>
    <aaa-text
      aria-label="Model error message"
      class="form-error text-error ion-margin-bottom"
      [ngClass]="{ hidden: isPristine || !errorMessage }"
      [attr.aria-hidden]="isPristine || !errorMessage" aria-live="polite"
    >
    {{ errorMessage }}
  </aaa-text>
    <aaa-button
      i18n
      expand="block"
      id="models-next-button"
      class="ion-margin-bottom"
      (click)="onNext()"
      [tabIndex]="0"
      [loading]="(isLoadingVehicleModelTypes$ | async) || (isLoading$ | async)">
      Next
      <aaa-icon name="arrow-right"></aaa-icon>
    </aaa-button>
  </aaa-modal>
</ion-content>
