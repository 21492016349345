import { Component, Input, ViewEncapsulation } from '@angular/core'
import { FormControlState } from 'ngrx-forms'

enum FORM_INPUTS {
  GENERIC_INPUT = 'INPUT',
  CHECKBOX_INPUT = 'DRR-CHECKBOX',
}

enum FORM_ERROR_TYPES {
  REQUIRED = 'required',
  PATTERN = 'pattern',
  MIN_LENGTH = 'minLength',
  MAX_LENGTH = 'maxLength',
}

const formValidInputs = [FORM_INPUTS.GENERIC_INPUT, FORM_INPUTS.CHECKBOX_INPUT]

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormErrorComponent {
  @Input() type: FORM_ERROR_TYPES
  @Input() name: string
  @Input() control: FormControlState<any>

  onControlClick(_: Event, name: string) {
    const possibleInputs = document.getElementsByName(name)
    const targetInput = Array.from(possibleInputs).find((input) =>
      formValidInputs.some(
        (validInput) =>
          validInput.toLocaleLowerCase() === input.tagName.toLocaleLowerCase()
      )
    )

    targetInput?.focus()
  }
}
