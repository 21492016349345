import { GenericCoordinates, GoogleLocation } from '../location.types'
import { GOOGLE_ADDRESS_COMPONENT_TYPES, GOOGLE_LOCATION_TYPES, GoogleCoordinates, PERMISSION_STATE, } from './types'
import { ABTestParams } from "../../ui/ui.types";
import { ABTestsState } from "../../ui/ui.reducer";
import { haversine } from "../../../shared/haversine";

export const hasUnnamedRoad = /[U-u]nnamed\s?[R-r]oad.?\s?/g
export const hasNumericRange = /^\s*[\d]+\s*-\s*[\d]+/
export const hasHighwayName = /\b(?:I[-\s]?\d{1,3}|US[-\s]?\d{1,3}|SR[-\s]?\d{1,3}|State[-\s]?\d{1,3}|County[-\s]?\d{1,3}|[A-Z]{1,2}[-\s]?\d{1,3}|(?:\w+\s+)?(?:Parkway|Pkwy|Highway|Hway|Hwy|Route|Rte|Rt|Expressway|Expy|Expwy|Exp|Turnpike|Tpke|Trnpk|Interstate|Int|Freeway|Fwy|Spur|Loop))\b/i

const getAddressComponentByType = (
  location: GoogleLocation,
  type: GOOGLE_ADDRESS_COMPONENT_TYPES
): GoogleLocation['address_components'][0] =>
  location?.address_components.find(
    (addressComponent) => addressComponent.types.indexOf(type) > -1
  )

export const getLocationsByType = (
  allLocations: Array<GoogleLocation>,
  type: GOOGLE_LOCATION_TYPES
): Array<GoogleLocation> =>
  allLocations.filter((result) => result.geometry.location_type === type)

const getNearSameNameRoute = (
  allLocations: Array<GoogleLocation>,
  routeComponent: GoogleLocation['address_components'][0],
  locationType: GOOGLE_LOCATION_TYPES
): GoogleLocation => {
  const nearLocations = getLocationsByType(allLocations, locationType)
  const nearSameNameRoute  = nearLocations.find((location) => {
    const nearestRouteComponent = getAddressComponentByType(
      location,
      GOOGLE_ADDRESS_COMPONENT_TYPES.ROUTE
    )

    return (
      nearestRouteComponent?.short_name.toLowerCase() ===
      routeComponent.short_name.toLowerCase()
    )
  })

  return nearSameNameRoute
}

export const getRecommendedLocation = (
  allLocations: Array<GoogleLocation>,
  preferredType: GOOGLE_LOCATION_TYPES
) => {
  const locationsByType = getLocationsByType(allLocations, preferredType)
    .filter((recommendedLocation) => getAddressComponentByType(
      recommendedLocation,
      GOOGLE_ADDRESS_COMPONENT_TYPES.ROUTE
    ))

  return (
    locationsByType.find((location) =>
      hasHighwayName.test(
        getAddressComponentByType(
          location,
          GOOGLE_ADDRESS_COMPONENT_TYPES.ROUTE
        )?.short_name
      )
    ) || locationsByType[0]
  )
}

export const guessNearestLocation = (
  allLocations: Array<GoogleLocation>,
  recommendedLocation: GoogleLocation,
  switchPriority: boolean = false,
): GoogleLocation => {
  const streetComponent = getAddressComponentByType(
    recommendedLocation,
    GOOGLE_ADDRESS_COMPONENT_TYPES.STREET_NUMBER
  )
  const routeComponent = getAddressComponentByType(
    recommendedLocation,
    GOOGLE_ADDRESS_COMPONENT_TYPES.ROUTE
  )

  if (
    (streetComponent && !hasNumericRange.test(streetComponent.short_name)) ||
    (!streetComponent &&
      routeComponent &&
      hasHighwayName.test(routeComponent.short_name))
  ) {
    return recommendedLocation
  } else if (!streetComponent && routeComponent) {
    const rangeInterpolatedLocation = getNearSameNameRoute(
      allLocations,
      routeComponent,
      GOOGLE_LOCATION_TYPES.RANGE_INTERPOLATED
    )
    const roofTopLocation = getNearSameNameRoute(
      allLocations,
      routeComponent,
      GOOGLE_LOCATION_TYPES.ROOFTOP
    )
    const sharedRouteNameLocation =
      switchPriority
        ? roofTopLocation || rangeInterpolatedLocation
        : rangeInterpolatedLocation || roofTopLocation

    if (sharedRouteNameLocation) {
      return sharedRouteNameLocation
    }
  }
}

export const parseLocation = (
  location: GoogleLocation,
  coords: GoogleCoordinates
): GoogleLocation => {
  if (!location) {
    throw new Error('Could not fetch location')
  }

  const addressComponents = location.address_components
    .filter(
      (item) =>
        item.types.indexOf('route') > -1 &&
        (hasUnnamedRoad.test(item.short_name) ||
          hasUnnamedRoad.test(item.long_name))
    )
    .map((unnamedRouteComponent) => ({
      ...unnamedRouteComponent,
      short_name: unnamedRouteComponent.short_name.replace(hasUnnamedRoad, ''),
      long_name: unnamedRouteComponent.long_name.replace(hasUnnamedRoad, ''),
    }))

  location.address_components[0] =
    addressComponents[0] || location.address_components[0]
  location.formatted_address = location.formatted_address?.replace(
    hasUnnamedRoad,
    ''
  )
  location.geometry.location.accuracy = coords.accuracy

  return Object.assign({}, location)
}

export const guessDefaultLocation = (
  allLocations: Array<GoogleLocation>,
  switchPriority: boolean = false,
): GoogleLocation => {
  const allLocationTypes = switchPriority ?
    [
      GOOGLE_LOCATION_TYPES.ROOFTOP,
      GOOGLE_LOCATION_TYPES.RANGE_INTERPOLATED
    ] : [
      GOOGLE_LOCATION_TYPES.RANGE_INTERPOLATED,
      GOOGLE_LOCATION_TYPES.ROOFTOP
    ]

  const locationsByType = allLocationTypes.reduce((locations: Array<GoogleLocation>, locationType) =>
    [
      ...locations,
      ...getLocationsByType(allLocations, locationType)
    ], [])

  return locationsByType[0]
}

const haversineMiles = (coordsApi: any, coordsLocation: GoogleCoordinates | GenericCoordinates) => {
  return haversine({
    latitude: coordsApi.lat(),
    longitude: coordsApi.lng()
  }, {
    latitude: coordsLocation.lat,
    longitude: coordsLocation.lng
  }, {
    unit: 'mile'
  })
}

export const getLocationFromResults = (results: GoogleLocation[], coords: GoogleCoordinates, abTestState: ABTestsState): GoogleLocation => {
  if (abTestState[ABTestParams.GOOGLE_RESULTS_FIRST_RESULT]) {
    console.log(`DEBUG ===> Using GOOGLE_RESULTS_FIRST_RESULT - TODO remove this`)
    return results[0]
  } else if (abTestState[ABTestParams.GOOGLE_RESULTS_BY_DISTANCE]) {
    console.log(`DEBUG ===> Using GOOGLE_RESULTS_BY_DISTANCE - TODO remove this`)
    results.forEach(location => {
      const dis = haversineMiles(location.geometry.location, coords)
      console.log(`DEBUG ===>>> ${dis} - ${location.formatted_address}`)
    })
    console.log(`DEBUG ===>>> ============`)
    const res = results
      .filter((location) => !location.types?.includes(GOOGLE_ADDRESS_COMPONENT_TYPES.PLUS_CODE))
      .sort((loc1, loc2) =>
        haversineMiles(loc1.geometry.location, coords) - haversineMiles(loc2.geometry.location, coords)
      )
    console.log(`DEBUG ===>>> Ordered by distance`)
    res.forEach(location => {
      const dis = haversineMiles(location.geometry.location, coords)
      console.log(`DEBUG ===>>> ${dis} - ${location.formatted_address}`)
    })
    return res[0]
  } else if (abTestState[ABTestParams.GOOGLE_RESULTS_SWITCH_PRIORITY]) {
    console.log(`DEBUG ===> Using GOOGLE_RESULTS_SWITCH_PRIORITY - TODO remove this`)
    return guessLocationFromCoordsResults(results, coords, true);
  }
  console.log(`DEBUG ===> Using GOOGLE_RESULTS_DEFAULT - TODO remove this`)
  return guessLocationFromCoordsResults(results, coords);
}

export const guessLocationFromCoordsResults = (
  results: GoogleLocation[],
  coords: GoogleCoordinates,
  switchPriority: boolean = false
): GoogleLocation => {
  const recommendedLocation = getRecommendedLocation(
    results,
    GOOGLE_LOCATION_TYPES.GEOMETRIC_CENTER
  )
  const defaultLocation = guessDefaultLocation(results, switchPriority)
  const plusCodeExcluded = results.filter((location) => !location.types?.includes(GOOGLE_ADDRESS_COMPONENT_TYPES.PLUS_CODE))

  const bestGuess =
    guessNearestLocation(results, recommendedLocation, switchPriority)
    || guessNearestLocation(results, defaultLocation, switchPriority)
    || recommendedLocation
    || plusCodeExcluded[0]
    || results[0]

  return parseLocation(bestGuess, coords)
}

export const requiresGpsPrompt = (permissionState: PERMISSION_STATE) =>
  [PERMISSION_STATE.UNKNOWN, PERMISSION_STATE.PROMPT].includes(permissionState)

export const __TEST__ = {
  getAddressComponentByType,
  getNearSameNameRoute,
}
