import { Component, EventEmitter, input, Output } from '@angular/core'
import { IonCard, IonIcon, IonItem, IonLabel, IonList } from "@ionic/angular/standalone";
import { TextComponent } from "@aaa-mobile/shared-components";
import { Vehicle } from "../vehicle.types";

@Component({
  selector: 'drr-vehicle-information-card',
  templateUrl: './vehicle-information-card.component.html',
  styleUrls: ['./vehicle-information-card.component.scss'],
  standalone: true,
  imports: [
    IonCard,
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
    TextComponent,
  ]
})
export class VehicleInformationCardComponent {

  vehicle = input.required<Vehicle>();

  @Output() yearClick: EventEmitter<void> = new EventEmitter<void>()
  @Output() makeClick: EventEmitter<void> = new EventEmitter<void>()
  @Output() modelClick: EventEmitter<void> = new EventEmitter<void>()
  @Output() colorClick: EventEmitter<void> = new EventEmitter<void>()

}
