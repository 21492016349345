import {
  ChangeDetectionStrategy,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import {
  CheckboxChangeEventDetail,
  IonCheckbox,
} from '@ionic/angular/standalone';
import { LabelPlacement, Alignment, Justify } from './checkbox.interfaces';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'drr-checkbox',
  standalone: true,
  imports: [IonCheckbox],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  schemas: [CUSTOM_ELEMENTS_SCHEMA], // TODO: Remove when Angular recognize the alignment property
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DrrCheckboxComponent),
      multi: true,
    },
  ],
})
export class DrrCheckboxComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() checked = false;
  @Input() labelPlacement: LabelPlacement = 'end';
  @Input() alignment: Alignment | undefined = 'center';
  @Input() justify: Justify | undefined = undefined;
  @Input() name: string | undefined;

  @Output() change = new EventEmitter<
    CustomEvent<CheckboxChangeEventDetail>
  >();
  @Output() focus = new EventEmitter<CustomEvent<void>>();
  @Output() blur = new EventEmitter<CustomEvent<void>>();

  private onChangeFn: (value: boolean) => void = () => {};
  private onTouchedFn: () => void = () => {};

  onChange(event: CustomEvent<CheckboxChangeEventDetail>) {
    const newValue = event.detail.checked;
    this.checked = newValue;
    this.onChangeFn(newValue);
    this.change.emit(event);
  }

  onBlur(event: CustomEvent<void>) {
    this.onTouchedFn();
    this.blur.emit(event);
  }

  onFocus(event: CustomEvent<void>) {
    this.focus.emit(event);
  }

  registerOnChange(fn: (_: boolean) => void): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouchedFn = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: boolean): void {
    this.checked = value;
  }
}
