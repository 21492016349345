<ion-card class="ion-no-margin">
  <ion-list class="ion-no-padding">
    <ion-item (click)="yearClick.observers.length ? yearClick.emit() : {}" [detail]="yearClick.observers.length">
      <ion-icon aria-hidden="true" name="calendar-outline" color="drr-medium" size="small" slot="start"></ion-icon>
      <aaa-text size="subheadline" color="text" weight="regular" i18n>Year</aaa-text>
      <aaa-text size="subheadline" color="text" weight="medium" slot="end">
        <ion-label class="ion-text-nowrap">{{ vehicle().year }}</ion-label>
      </aaa-text>
    </ion-item>
    <ion-item (click)="makeClick.observers.length ? makeClick.emit() : {}" [detail]="makeClick.observers.length">
      <ion-icon aria-hidden="true" name="pricetag-outline" color="drr-medium" size="small" slot="start"></ion-icon>
      <aaa-text size="subheadline" color="text" weight="regular" i18n>Make</aaa-text>
      <aaa-text size="subheadline" color="text" weight="medium" slot="end">
        <ion-label class="ion-text-nowrap">{{ vehicle().make }}</ion-label>
      </aaa-text>
    </ion-item>
    <ion-item (click)="modelClick.observers.length ? modelClick.emit() : {}" [detail]="modelClick.observers.length">
      <ion-icon aria-hidden="true" name="car-outline" color="drr-medium" size="small" slot="start"></ion-icon>
      <aaa-text size="subheadline" color="text" weight="regular" i18n>Model</aaa-text>
      <aaa-text size="subheadline" color="text" weight="medium" slot="end">
        <ion-label class="ion-text-nowrap">{{ vehicle().model }}</ion-label>
      </aaa-text>
    </ion-item>
    <ion-item (click)="colorClick.observers.length ? colorClick.emit() : {}" [detail]="colorClick.observers.length">
      <ion-icon aria-hidden="true" name="color-palette-outline" color="drr-medium" size="small" slot="start"></ion-icon>
      <aaa-text size="subheadline" color="text" weight="regular" i18n>Color</aaa-text>
      <aaa-text size="subheadline" color="text" weight="medium" slot="end">
        <ion-label class="ion-text-nowrap">{{ vehicle().color }}</ion-label>
      </aaa-text>
    </ion-item>
  </ion-list>
</ion-card>
