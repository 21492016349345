import { Component, OnInit } from '@angular/core'
import { AAAStore } from '../../../store/root-reducer'
import { select, Store } from '@ngrx/store'
import { selectIsVehicleYearsStep, selectVehicleStep, selectWorkingVehicle } from '../vehicle.selectors'
import { Observable } from 'rxjs'
import { selectIsAbTestParam, selectShowMenu } from '../../ui/ui.selectors'
import { TaggingService } from '../../tagging/tagging.service';
import events from '../../tagging/events';
import { buildTitle } from '../../../shared/utils/title';
import { Title } from '@angular/platform-browser';
import { RapService } from '../../rap/rap.service';
import { selectIsSecure } from '../../auth/auth.selectors'
import { VehicleData } from '../../member/member.types'
import { selectMemberVehicles } from '../../member/member.selectors'
import { ABTestParams } from '../../ui/ui.types'
import { ConfigService } from '../../config/config.service'
import { AbstractComponent } from '../../../shared/abstract.component'
import { first } from 'rxjs/operators'
import { clearActiveVehicle } from '../../member/member.actions'
import { editVehicle, setVehicleStep } from '../vehicle.actions'
import { VehicleEditState } from '../vehicle.reducer'

const TITLE_VEHICLE = () => $localize`Select Vehicle`

@Component({
  selector: 'app-vehicle-step',
  templateUrl: './vehicle-step.component.html',
  styleUrls: ['./vehicle-step.component.scss'],
})
export class VehicleStepComponent extends AbstractComponent implements OnInit {
  step$: Observable<string> = this.store$.pipe(select(selectVehicleStep))
  showMenu$: Observable<boolean> = this.store$.pipe(select(selectShowMenu))
  showMenu

  editYear$ = this.store$.pipe(
    select(selectIsVehicleYearsStep)
  )

  isSecure$: Observable<boolean> = this.store$.pipe(select(selectIsSecure))

  isPlateToVin$: Observable<boolean> = this.store$.pipe(
    select(selectIsAbTestParam, ABTestParams.LICENSE_PLATE_TO_VIN_B)
  )

  memberVehicles$: Observable<VehicleData[]> = this.store$.pipe(
    select(selectMemberVehicles)
  )

  vehicle$: Observable<VehicleEditState> = this.store$.pipe(select(selectWorkingVehicle))

  constructor(
    private store$: Store<AAAStore>,
    private taggingService: TaggingService,
    private titleService: Title,
    private rapService: RapService,
    private configService: ConfigService,
  ) {
    super()
    this.titleService.setTitle(buildTitle(TITLE_VEHICLE(), this.rapService.isRapUser()))
    this.taggingService.setPageEvent(events.vehicle.VEHICLE_PAGE_PROMPT, events.vehicle.VEHICLE_PAGE_TYPE)
  }

  ngOnInit() {
    this.subscriptions.push(
      this.showMenu$.subscribe((show) => {
        this.showMenu = !this.configService.getConfig().nativeAppView && show
      })
    )
  }

  handleBack() {
    this.vehicle$.pipe(first()).subscribe(vehicle => {
      this.store$.dispatch(clearActiveVehicle())

      this.store$.dispatch(setVehicleStep({
        payload: {
          step: ''
        }
      }))

      this.store$.dispatch(
        editVehicle({
          payload: {
            vehicle: vehicle as VehicleData,
            isActive: false,
            section: '',
          },
        })
      )
    })
  }
}
