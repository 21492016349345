import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { VehicleEditState } from '../vehicle.reducer'
import { select, Store } from '@ngrx/store'
import { selectWorkingVehicle } from '../vehicle.selectors'
import { AAAStore } from '../../../store/root-reducer'
import { ActivatedRoute } from '@angular/router'
import { VehicleSections } from '../../ui/ui.types'

@Component({
  selector: 'app-vehicle-header-step',
  templateUrl: './vehicle-header-step.component.html',
  styleUrls: ['./vehicle-header-step.component.scss'],
})
export class VehicleHeaderStepComponent {
  workingVehicle$: Observable<VehicleEditState> = this.store$.pipe(
    select(selectWorkingVehicle)
  )

  constructor(
    private store$: Store<AAAStore>,
    private route: ActivatedRoute
  ) {}

  get isPlateToVinSection() {
    const vehicleSection = this.route.snapshot.queryParams['section']
    return vehicleSection === VehicleSections.PLATE ||
      vehicleSection === VehicleSections.CONFIRM_OPTIONS ||
      vehicleSection === VehicleSections.CONFIRM
  }
}
