<div class="dialog">
  <h1 i18n mat-dialog-title>
    Special Assistance
  </h1>
  <div mat-dialog-content
    class="modal-form">

    <div>
      <drr-checkbox
        i18n
        name="hearingImpaired"
        (change)="driveDirectionsChange($event)"
        [checked]="!!hearingImpairedDetails">I'm hearing impaired.</drr-checkbox>
    </div>

  </div>

  <div mat-dialog-actions>
    <aaa-button
      innerStyle="display: flex; justify-content: center"
      i18n
      (click)="handleSpecialAssistanceSubmit()">
      Confirm
    </aaa-button>
  </div>
  <button i18n-aria-label
    class="mat-dialog-close"
    aria-label="dismiss this message"
    (click)="onClose()">
    <icon-close></icon-close>
  </button>
</div>
