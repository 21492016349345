export default {
  VEHICLE_YEAR_SELECT: 'Select Year',
  VEHICLE_MAKE_SELECT: 'Select Make',
  VEHICLE_MODEL_SELECT: 'Select Model',
  VEHICLE_OTHER_MODEL_SELECT: 'Select Other Model',
  VEHICLE_PRELOADED_SELECT: 'Select Saved Vehicle',
  VEHICLE_COMPLETE: 'Vehicle Selection Complete',
  VEHICLE_EDIT: 'Edit Saved Vehicle',
  VEHICLE_DELETE: 'Delete Vehicle',
  VEHICLE_SELECT_COLOR: 'Select Vehicle Color',
  VEHICLE_SELECT_AWD_4WD: '4WD or AWD:',

  VEHICLE_PAGE_TYPE: 'What vehicle needs service?',
  VEHICLE_PAGE_PROMPT: 'Vehicles screen displayed',
  VEHICLE_SUSPICIOUS_PAGE_TYPE: 'Vehicle',
  PAGE_TYPE: 'Vehicle Selection',
  PAGE_NAME_VEHICLE_LIST: 'My Vehicle List',
  PAGE_NAME_SELECT_YEAR: 'Select Vehicle Year',
  PAGE_NAME_SELECT_MAKE: 'Select Vehicle Make',
  PAGE_NAME_SELECT_MODEL: 'Select Vehicle Model',
  PAGE_NAME_VEHICLE_PLATE: 'Vehicle Plate',
  PAGE_NAME_VEHICLE_CONFIRM_OPTIONS: 'Vehicle Plate Confirmation Options',
  PAGE_NAME_VEHICLE_CONFIRM: 'Vehicle Plate Confirmation',

  PLATE_TO_VIN_DONT_KNOW_LICENSE: 'I don\'t know my license plate',
  PLATE_TO_VIN_CONFIRM_VEHICLE: 'Add Vehicle: Plate To Vin Vehicle Selection Complete',
  PLATE_TO_VIN_VEHICLE_NOT_LISTED: 'My vehicle is not listed above',
}
