import { Component, EventEmitter, Input, Output } from '@angular/core'
import { EditService } from '../../../shared/services/edit.service'
import { AddVehicleModes } from '../vehicle.types'
import { VehicleSections } from '../../ui/ui.types'
import { setResetPlateToVinForm } from '../../ui/forms/forms.reducer'
import { Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'

@Component({
  selector: 'drr-add-vehicle-options',
  templateUrl: './add-vehicle-options.component.html',
  styleUrls: ['./add-vehicle-options.component.scss'],
})
export class AddVehicleOptionsComponent {
  @Input() isOpen: boolean
  @Output() isOpenChange = new EventEmitter<boolean>()

  constructor(
    private store$: Store<AAAStore>,
    private editService: EditService,
  ) {}

  addVehicleByLicense() {
    this.store$.dispatch(setResetPlateToVinForm())
    this.editService.editVehicleSection(false, '', VehicleSections.PLATE)
  }

  addVehicleByMakeModel() {
    this.editService.editVehicleSection(false, '', VehicleSections.YEARS)
  }

  addVehicleAction(event: CustomEvent) {
    this.isOpenChange.emit(false)
    if (event.detail.data.action === AddVehicleModes.PLATE_VIN) {
      this.addVehicleByLicense()
    } else if (event.detail.data.action === AddVehicleModes.MAKE_MODEL) {
      this.addVehicleByMakeModel()
    }
  }

  addVehicleOptions = () => [
    {
      text: $localize`Add Vehicle By License Plate`,
      data: {
        action: AddVehicleModes.PLATE_VIN,
      },
    },
    {
      text: $localize`Add Vehicle By Make/Model`,
      data: {
        action: AddVehicleModes.MAKE_MODEL,
      },
    },
    {
      text: $localize`Cancel`,
      role: 'cancel',
      data: {
        action: 'cancel',
      },
    },
  ]

}
