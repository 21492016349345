<app-header-redesign>
  <app-back-button slot="start" (aaaClick)="navigateBack()" />
  <ion-title>
    <app-logo />
  </ion-title>
  <app-closable-header slot="end" />

  <app-stepper-redesign />
</app-header-redesign>

<ion-content>
  <ion-grid class="ion-padding display-flex">
    <ion-row>
      <ion-col>
        <aaa-text i18n role="heading" aria-level="1" size="title3" color="dark">Vehicle Selection</aaa-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <aaa-text i18n role="heading" aria-level="2" size="subheadline" color="dark">Which vehicle needs service?</aaa-text>
      </ion-col>
    </ion-row>

    <ng-container *ngIf="((memberVehicles$ | async) || []); let vehicles">
      <ion-row class="ion-margin-top">
        <ion-col class="ion-margin-top">
          <ng-container *ngFor="let vehicle of vehicles; trackBy: trackByMethod; let index = index">
            <app-vehicle-summary-step
              *ngIf="!(editYear$ | async) || (workingVehicle$ | async).id === vehicle.id"
              [vehicle]="vehicle"
              (selectVehicle)="assignVehicle($event)"
              [tabIndexPosition]="300 + (index + 1)"
              [first]="index === 0"
            >
            </app-vehicle-summary-step>
          </ng-container>
        </ion-col>
      </ion-row>
    </ng-container>

    <ng-container *ngIf="isLoading$ | async">
      <ion-row class="ion-padding ion-justify-content-center">
        <ion-col size="auto">
          <ion-spinner color="dark"></ion-spinner>
        </ion-col>
      </ion-row>
    </ng-container>

    @if (isEmptyVehicles$ | async) {
      <ion-row class="empty-list">
        <ion-col>
          <aaa-icon name="car-off" size="xx-large" color="dark" />
          @if (allowAddVehicle$ | async) {
            <aaa-text i18n role="heading" aria-level="4" size="headline" class="description" weight="regular">
              Add a vehicle by clicking the button below
            </aaa-text>
          }
        </ion-col>
      </ion-row>
    }

    @if (allowAddVehicle$ | async) {
      <ion-row class="ion-margin-top align-end" [style.flex-grow]="(isEmptyVehicles$ | async) ? 0 : 1">
        <ion-col>
          <aaa-button appCtaClickEvent="Add Vehicle" class="create-vehicle-btn" variant="outline" expand="block" (click)="addVehicle()">
            <aaa-icon name="add"></aaa-icon>
            <ng-container i18n>Add a vehicle</ng-container>
          </aaa-button>
        </ion-col>
      </ion-row>
    }

  </ion-grid>

  <drr-add-vehicle-options
    [isOpen]="isAddVehicleOptionsOpen"
    (isOpenChange)="isAddVehicleOptionsOpen = $event"
  ></drr-add-vehicle-options>

</ion-content>
