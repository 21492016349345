import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Observable } from 'rxjs'
import { FormGroupState } from 'ngrx-forms'
import { PlateToVinFormState } from '../../../modules/ui/forms/forms.reducer'
import { select, Store } from '@ngrx/store'
import { selectPlateToVinForm } from '../../../modules/ui/forms/forms.selectors'
import { AAAStore } from '../../../store/root-reducer'

@Component({
  selector: 'app-input-license-plate',
  templateUrl: './input-license-plate.component.html',
  styleUrls: ['./input-license-plate.component.scss'],
})
export class InputLicensePlateComponent {
  licensePlate: string

  form$: Observable<FormGroupState<PlateToVinFormState>> =
    this.store$.pipe(select(selectPlateToVinForm))

  @Input() error: boolean
  @Output() licensePlateChange: EventEmitter<string> = new EventEmitter<string>()

  constructor(
    private store$: Store<AAAStore>,
  ) {}

  sanitizeKeyup(value: string): string {
    return value.replace(
      /[^a-zA-Z0-9]|\s{2,}/g, ''
    ).toUpperCase()
  }

  handleBlurText(event: CustomEvent) {
    if (event.detail.value) {
      this.licensePlate = this.sanitizeKeyup(event.detail.value)
    }
    this.licensePlateChange.emit(this.licensePlate)
  }

  onKeyup(event: KeyboardEvent): void {
    const inputElement = event.target as HTMLInputElement
    const cursorPosition = inputElement.selectionStart || 0
    inputElement.value = this.sanitizeKeyup(inputElement.value)
    inputElement.setSelectionRange(cursorPosition, cursorPosition)
  }
}
