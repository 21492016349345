<ion-checkbox
  [checked]="checked"
  [disabled]="disabled"
  [labelPlacement]="labelPlacement"
  [alignment]="alignment"
  [justify]="justify"
  [attr.name]="name"
  (ionChange)="onChange($event)"
  (ionBlur)="onBlur($event)"
  (ionFocus)="onFocus($event)"
  color="drr-primary"
>
  <ng-content></ng-content>
</ion-checkbox>
