import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core'
import { Observable } from 'rxjs'
import { AAAStore } from '../../../store/root-reducer'
import { Store, select } from '@ngrx/store'
import {
  requestVehicleYears,
  setVehicleYear,
  createNewVehicle,
  VEHICLE_YEARS,
} from '../vehicle.actions'
import { TaggingService } from '../../tagging/tagging.service'
import { selectIsLoading } from '../../ui/loading/loading.selectors'
import { AbstractResponsiveComponent } from '../../../shared/abstract-responsive.component'
import events from '../../tagging/events'
import { selectAllowAddVehicle, selectIsRapUser } from '../../auth/auth.selectors'
import { selectEditOrAddVehicleDesc, selectIsVehicleYearsStep } from '../vehicle.selectors'
import { AdobeEventTypes } from '../../tagging/tagging.types'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'

@Component({
  selector: 'app-create-vehicle-step',
  templateUrl: './create-vehicle-step.component.html',
  styleUrls: ['./create-vehicle-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateVehicleStepComponent extends AbstractResponsiveComponent implements OnInit {
  isLoading$: Observable<any> = this.store$.pipe(
    select(selectIsLoading(VEHICLE_YEARS.ACTION))
  )

  allowAddVehicle$: Observable<boolean> = this.store$.pipe(
    select(selectAllowAddVehicle)
  )

  isVehicleYearsStep$: Observable<boolean> = this.store$.pipe(
    select(selectIsVehicleYearsStep)
  )

  isRapUser$ = this.store$.pipe(select(selectIsRapUser))
  editOrAddVehicleDesc$ = this.store$.pipe(select(selectEditOrAddVehicleDesc))
  editOrAddVehicleDesc: string

  @Input() isCreatingNew = true
  @Input() isMember = false
  @Input() tabIndexPosition = 0;
  @Output() back = new EventEmitter()

  constructor(
    private store$: Store<AAAStore>,
    private tagging: TaggingService,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }

  ngOnInit() {
    this.store$.dispatch(requestVehicleYears())
    this.subscriptions.push(
      this.editOrAddVehicleDesc$.subscribe(
        editOrAdd => this.editOrAddVehicleDesc = editOrAdd
      )
    )
  }

  yearChange(year: number | string) {
    if (year) {
      this.tagging.setClickEvent(
        events.vehicle.VEHICLE_YEAR_SELECT,
        events.vehicle.VEHICLE_PAGE_TYPE
      )

      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.CTA,
        eventValue: `${this.editOrAddVehicleDesc} ${events.vehicle.VEHICLE_YEAR_SELECT}`
      })

      this.store$.dispatch(
        this.isCreatingNew
          ? createNewVehicle({ payload: { year: Number(year) } })
          : setVehicleYear({ payload: Number(year) })
      )
    }
  }

  handleBack() {
    this.back.emit()
  }
}
