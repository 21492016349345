<aaa-modal
  contentMode="scroll"
  [padding]="false"
  [isOpen]="isModalOpen"
  [initialBreakpoint]="1"
  (aaaModalDidDismiss)="setOpen(false)"
  [breakpoints]="[0, 1]">
    <ion-header>
      <ion-toolbar>
        <ion-title i18n>Select State</ion-title>
        <ion-buttons slot="end">
          <aaa-button (click)="setOpen(false)" class="close-button" size="small" variant="secondary">
            <aaa-icon name="close-outline"></aaa-icon>
          </aaa-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding-bottom">
      <ion-list>
        <ion-radio-group mode="ios" [value]="selectedUsState">
          <ion-item *ngFor="let state of usStatesOptions$ | async" (click)="selectState(state)" button>
            <ion-radio
              color="drr-primary"
              justify="start"
              [value]="state"
            >
              <ion-row class="ion-align-items-center">
                <ion-col>
                  <aaa-text color="dark" size="subheadline">{{ state }}</aaa-text>
                </ion-col>
              </ion-row>
            </ion-radio>
          </ion-item>
        </ion-radio-group>
      </ion-list>
    </ion-content>
</aaa-modal>

<aaa-input
  label="State"
  i18n-label
  [variant]="error ||
    (form$ | async)?.controls?.state?.isTouched &&
    (form$ | async)?.controls?.state?.errors?.required ? 'danger': 'secondary'"
  [value]="selectedUsState"
  (click)="setOpen(true)"
>
</aaa-input>
