import {
  AddressWithLandmark,
  GoogleLocationMarker,
  LastSearchLocation,
  LocationWithMarker,
  TowingDistanceCoords,
  TowLocation,
} from '../location.types'
import { props, createAction } from '@ngrx/store'
import { createAsyncConstants } from '../../../shared/utils'
import { Option } from '../../ui/ui.types'

export const ADDRESS_LOOKUP = createAsyncConstants('ADDRESS_LOOKUP')
export const SET_TOW_DESTINATION = createAsyncConstants('SET_TOW_DESTINATION')
export const SET_TOW_DESTINATION_MARKER = 'SET_TOW_DESTINATION_MARKER'
export const RESET_TOW_DESTINATION = 'RESET_TOW_DESTINATION'
export const GET_TOW_DESTINATION_COORDINATES = 'GET_TOW_DESTINATION_COORDINATES'
export const CONFIRM_TOW_DISTANCE = 'CONFIRM_TOW_DISTANCE'
export const SET_AAR_ADDRESS = 'SET_AAR_ADDRESS'
export const CHECK_TOW_DISTANCE = 'CHECK_TOW_DISTANCE'
export const SET_LAST_TOW_TO_SEARCH_LOCATION = 'SET_LAST_TOW_TO_SEARCH_LOCATION'
export const SET_TOWING_STEP = 'SET_TOWING_STEP'
export const SET_PASSENGERS = 'SET_PASSENGERS'
export const CONFIRM_TOW_SELECTION = 'CONFIRM_TOW_SELECTION'

export enum NON_AAR_TOWING_NAMES {
  CUSTOM = 'Custom Address',
  HOME = 'Home',
}

export const resetTowDestination = createAction(RESET_TOW_DESTINATION)

export const confirmTowDistance = createAction(CONFIRM_TOW_DISTANCE)

export const checkTowDistance = createAction(
  CHECK_TOW_DISTANCE,
  props<{ payload: TowingDistanceCoords }>()
)

export const requestSetTowDestination = createAction(
  SET_TOW_DESTINATION.REQUEST,
  props<{ payload: TowLocation | GoogleLocationMarker }>()
)

export const completeSetTowDestination = createAction(
  SET_TOW_DESTINATION.SUCCESS,
  props<{ payload: TowLocation }>()
)

export const notifySetTowDestinationFailure = createAction(
  SET_TOW_DESTINATION.FAILURE
)

export const getTowDestinationCoordinates = createAction(
  GET_TOW_DESTINATION_COORDINATES,
  props<{ payload: string }>()
)

export const requestAddressLookup = createAction(
  ADDRESS_LOOKUP.REQUEST,
  props<{ payload: AddressWithLandmark }>()
)

export const completeAddressLookup = createAction(
  ADDRESS_LOOKUP.SUCCESS,
  props<{ payload: LocationWithMarker }>()
)

export const notifyAddressLookupFailure = createAction(ADDRESS_LOOKUP.FAILURE)

export const setAARAddress = createAction(
  SET_AAR_ADDRESS,
  props<{ payload: LocationWithMarker }>()
)

export const setTowDestinationMarker = createAction(
  SET_TOW_DESTINATION_MARKER,
  props<{ payload: GoogleLocationMarker }>()
)

export const setLastTowToSearchLocation = createAction(
  SET_LAST_TOW_TO_SEARCH_LOCATION,
  props<{ payload: LastSearchLocation }>()
)

export const setTowingStep = createAction(
  SET_TOWING_STEP,
  props<{ payload: { step: string } }>()
)

export const setPassengers = createAction(
  SET_PASSENGERS,
  props<{ payload: Option }>()
)

export const confirmTowSelection = createAction(
  CONFIRM_TOW_SELECTION,
  props<{ payload: boolean }>()
)
