<app-header-redesign>
  <app-back-button slot="start" (click)="handleBack()"/>
  <ion-title>
    <app-title i18n>Add Vehicle</app-title>
  </ion-title>
  <app-closable-header slot="end"/>

  <app-stepper-redesign/>
</app-header-redesign>

<ion-content>
  <form name="form"
        id="plate-to-vin-form"
        (ngSubmit)="handleAddVehicle()"
        [ngClass]="{'error': !form.isValid }"
        [ngrxFormState]="form">
    <ion-grid class="ion-padding-horizontal full-height">
      <ion-col class="ion-justify-content-start">
        <ion-row class="ion-padding-bottom">
          <ion-col>
            <aaa-text i18n role="heading" aria-level="2" size="subheadline" color="dark">
              What is your vehicle's license plate?
            </aaa-text>
          </ion-col>
        </ion-row>

        <ion-row class="ion-padding-vertical">
          <ion-col>
            <app-input-license-plate
              [error]="errorLicensePlate || displayError"
              (licensePlateChange)="searchVehicle()"
              [tabIndex]="0"
            ></app-input-license-plate>
          </ion-col>
        </ion-row>
        <ion-row class="ion-padding-vertical">
          <ion-col>
            <app-input-us-state
              (selectedUsStateChange)="setState($event)"
              [selection]="state"
              [error]="errorState || displayError"
              [tabIndex]="1"
            ></app-input-us-state>
          </ion-col>
        </ion-row>
        <ion-row class="ion-padding-vertical">
          <ion-col>
            <drr-input-color
              (selectedColorChange)="setColor($event)"
              [selectedColor]="color"
              [error]="errorColor"
              [tabIndex]="2"
            ></drr-input-color>
          </ion-col>
        </ion-row>
        @if (displayError) {
          <ion-row class="ion-no-padding no-vehicle-found-row">
            <ion-col>
              <img src="assets/drrweb-lib/images/car/not_found.png" alt="no vehicles found" i18n-alt class="no-vehicle-found-img"/>
              <aaa-text size="title3" align="center" class="not-found-title" i18n>No vehicles found</aaa-text>
              <aaa-text size="subheadline" align="center" color="subtle" i18n>The License Plate or State you entered is not correct. Please try again or add your vehicle using Make/Model.</aaa-text>
            </ion-col>
          </ion-row>
        }
      </ion-col>

      <ion-col class="ion-padding-bottom ion-justify-content-end" size="auto">
        <ion-row class="ion-padding-vertical">
          <ion-col>
            <aaa-button
              i18n
              id="add-vehicle-btn"
              type="submit"
              [disabled]="displayError"
              [loading]="submitPending"
              expand="block"
              variant="primary"
              [tabIndex]="3"
            >
              Add Vehicle
            </aaa-button>
          </ion-col>
        </ion-row>
        <ng-container *ngIf="displayAddVehicle; else dontKnowLicensePlate">
          <ion-row>
            <ion-col>
              <aaa-button
                i18n
                id="add-make-model-btn"
                expand="block"
                variant="outline"
                [tabIndex]="4"
                (click)="clearVehicleAndGoToYears()"
              >
                Add Vehicle By Make/Model
              </aaa-button>
            </ion-col>
          </ion-row>
        </ng-container>
        <ng-template #dontKnowLicensePlate>
          <ion-row>
            <ion-col>
              <aaa-button
                i18n
                id="dont-know-license-btn"
                expand="block"
                variant="outline"
                [tabIndex]="5"
                (click)="handleDontKnowLicensePlate()"
              >
                I Don't Know My License Plate
              </aaa-button>
            </ion-col>
          </ion-row>
        </ng-template>
      </ion-col>
    </ion-grid>
  </form>
</ion-content>
