import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Vehicle } from '../../member/member.types';
import { Make } from '../vehicle.types'
import { VehicleUtils } from '../vehicle.utils'

@Component({
  selector: 'app-vehicle-summary',
  templateUrl: './vehicle-summary.component.html',
  styleUrls: ['./vehicle-summary.component.scss'],
})
export class VehicleSummaryComponent {
  @Input() vehicle!: Vehicle;
  @Input() allowEdit = false; // Controls visibility of edit options
  @Input() isActive = false;
  @Input() tabIndexPosition = 0;

  @Output() vehicleClick = new EventEmitter<void>();
  @Output() deleteVehicle = new EventEmitter<void>();

  isEditing = false;

  constructor(private vehicleUtils: VehicleUtils) {}

  buildMake(makeLabel: string): Make {
    return this.vehicleUtils.buildMake(makeLabel)
  }

  onVehicleClick() {
    this.vehicleClick.emit();
  }

  toggleEditing() {
    this.isEditing = !this.isEditing;
  }

  onDelete() {
    this.deleteVehicle.emit();
  }
}
