import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core'

import { WizardRoutingModule } from './wizard-routing.module'
import { WizardComponent } from './wizard.component'
import { SharedModule } from '../../shared'
import { issueComponents } from '../issue/issue.components.declarations'
import { vehicleComponents, vehicleModules } from '../vehicle/vehicle.components.declarations'
import { locationComponents } from '../location/location.components.declarations'
import { submitComponents } from '../submit/submit.component.declarations'

import { WizardService } from './wizard.service'
import { NgrxFormsModule } from 'ngrx-forms'

import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { LocationModule } from '../location/location.module'
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap'
import { NgxMaskDirective } from 'ngx-mask';
import { IonicModule } from '@ionic/angular'
import {
  ButtonComponent,
  IconComponent,
  InputComponent,
  ModalComponent,
  TextComponent
} from '@aaa-mobile/shared-components'
import { VehicleColorListComponent } from '../vehicle/vehicle-color-list'
import { HeaderRedesignComponent } from '../../shared/layouts/header-redesign/header/header.component';
import { ClosableHeaderComponent } from '../../shared/layouts/closable-header/closable-header.component'
import { LogoComponent } from '../../shared/layouts/header-redesign/logo/logo.component'
import { MenuButtonComponent } from '../../shared/layouts/header-redesign/menu-button/menu-button.component'
import { MenuComponent } from '../../shared/layouts/header-redesign/menu/menu.component';
import { StepperRedesignComponent } from '../../shared/layouts/header-redesign/stepper/stepper.component';
import { BackButtonComponent } from '../../shared/layouts/header-redesign/back-button/back-button.component'
import { TitleComponent } from '../../shared/layouts/header-redesign/title/title.component'
import { ImageComponent } from '../../shared/controls/image/image.component'
import { DrrCheckboxComponent } from '../../shared/controls/checkbox/checkbox.component'
import { VehicleInformationCardComponent } from '../vehicle/vehicle-information-card/vehicle-information-card.component'
import { DrrInputColor } from '../../shared/controls/input-color/input-color.component'

@NgModule({
  declarations: [
    WizardComponent,
    ...issueComponents(),
    ...vehicleComponents(),
    ...locationComponents(),
    ...submitComponents(),
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    NgrxFormsModule,
    WizardRoutingModule,
    SharedModule,
    NgxMaskDirective,
    LocationModule,
    NgbRatingModule,
    IonicModule,
    IconComponent,
    ButtonComponent,
    TextComponent,
    ModalComponent,
    DrrCheckboxComponent,
    InputComponent,
    VehicleColorListComponent,
    ...vehicleModules(),
    HeaderRedesignComponent,
    ClosableHeaderComponent,
    LogoComponent,
    MenuButtonComponent,
    MenuComponent,
    StepperRedesignComponent,
    BackButtonComponent,
    TitleComponent,
    ImageComponent,
    DrrInputColor,
    VehicleInformationCardComponent,
  ],
  providers: [WizardService]
})
export class WizardModule {}
