<app-header-redesign>
  <app-back-button slot="start" (click)="handleBack()"/>
  <ion-title>
    <app-title i18n>Add Vehicle</app-title>
  </ion-title>
  <app-closable-header slot="end"/>

  <app-stepper-redesign/>
</app-header-redesign>
<ion-content>
  <ion-grid class="ion-padding-horizontal full-height">
    <ion-col class="ion-justify-content-start">
      <ion-row class="ion-padding-bottom">
        <aaa-text i18n role="heading" aria-level="2" size="subheadline" color="dark">
          Please select your vehicle:
        </aaa-text>
      </ion-row>

      <ion-row class="ion-padding-vertical">
        <ng-container *ngFor="let vehicle of (vehiclesByPlate$ | async); let index = index">
          <app-vehicle-summary
            [vehicle]="vehicle"
            [tabIndexPosition]="index"
            (vehicleClick)="handleSelectVehicle(vehicle)"
          ></app-vehicle-summary>
        </ng-container>
      </ion-row>
    </ion-col>

    <ion-col class="ion-padding-bottom ion-justify-content-end" size="auto">
      <ion-row class="ion-padding-vertical">
        <ion-col>
          <aaa-button
            i18n
            id="dont-know-license-btn"
            expand="block"
            variant="outline"
            [tabIndex]="5"
            (click)="handleVehicleNotListed()"
          >
            My Vehicle Is Not Listed Above
          </aaa-button>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-grid>

  <drr-add-vehicle-options
    [isOpen]="isAddVehicleOptionsOpen"
    (isOpenChange)="isAddVehicleOptionsOpen = $event"
  ></drr-add-vehicle-options>
</ion-content>

