import { Injectable } from '@angular/core'
import { VehicleCallData } from "../../modules/dashboard/calls.types";
import { VehicleData, VehicleMemberData } from "../../modules/member/member.types";

@Injectable({
  providedIn: 'root',
})
export class VehicleAdapter {

  constructor() {}

  adaptMemberVehicle = (vehicle: VehicleData): VehicleMemberData => ({
    ...vehicle,
    year: Number(vehicle.year),
    licensePlate: vehicle.tag,
  })

  adaptCallVehicle = (vehicle: VehicleCallData): VehicleCallData => ({
    ...vehicle,
    licensePlate: vehicle.tag,
    licensePlateState: vehicle.state,
  })
}
