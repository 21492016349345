import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, } from '@angular/core'
import { CustomMarker } from '../../../shared/controls/custom-marker-base/custom-marker-base'

import { clone, isEmpty } from 'lodash'
import { DEFAULT_MARKER_DETAILS, MarkerDetails } from '../ui.types'
import { GoogleLocationMarker } from '../../location/location.types'
import { isRedesign } from '../../../shared/utils/cookies';

declare let google: any

@Component({
  selector: 'app-custom-marker',
  templateUrl: './custom-marker.component.html',
  styleUrls: ['./custom-marker.component.scss'],
})
export class CustomMarkerComponent {
  locationValue: GoogleLocationMarker = {
    lat: null,
    lng: null,
  }

  showNotesValue = false

  @Input() get location(): GoogleLocationMarker {
    return this.locationValue
  }

  @Input() set showNotes(show) {
    this.showNotesValue = show
  }

  get showNotes(): boolean {
    return this.showNotesValue && Boolean(this.locationValue?.notes)
  }

  @Input() tabIndex = 0
  @Input() map

  set location(value: GoogleLocationMarker) {
    this.locationValue = value
    if (this.marker) {
      const coords = this.location
        ? new google.maps.LatLng(this.location.lat, this.location.lng)
        : null
      this.panToMarker(coords, this.panOnMove)
      this.marker.refreshMarker(coords, {})
      this.updateCircle(value)
    }
  }

  @Input() data: MarkerDetails = DEFAULT_MARKER_DETAILS
  @Input() event: any = {}
  @Input() type: String = 'car'
  @Input() panOnPlace: boolean
  @Input() panOnMove: boolean
  @Input() zoomOnMove: boolean | number

  @Output() markerClicked: EventEmitter<any> = new EventEmitter<any>()
  @Output() markerTouchStart: EventEmitter<any> = new EventEmitter<any>()
  @Output() dragEnd: EventEmitter<any> = new EventEmitter<any>()

  @ViewChild('customMarker') customMarker: ElementRef

  styleClass: String = 'default-marker'
  display: Boolean = true
  marker: any
  loaded = false

  circle: google.maps.Circle

  latLng: any = null

  constructor() {
    this.showNotes = false
    this.initialize = this.initialize.bind(this)
    this.handleMarkerClicked = this.handleMarkerClicked.bind(this)
  }

  ngOnDestroy() {
    this.removeMarker()
  }

  ngAfterViewInit() {
    this.initialize()
  }

  /**
   * Notify when marker was clicked
   */
  handleMarkerClicked($event) {
    if ($event) {
      $event.preventDefault()
      $event.stopPropagation()
    }

    this.markerClicked.emit()
  }

  handleMarkerTouchStart($event) {
    if (this.data && this.data.draggable) {
      return
    }

    if ($event) {
      $event.stopPropagation()
      $event.preventDefault()
    }

    this.markerTouchStart.emit($event)
  }

  /**
   * Notify when marker was dragged
   */
  markerDragged(coords) {
    this.panToMarker(coords, this.panOnMove)
    this.dragEnd.next(coords)
  }

  /**
   * Pan to marker location
   */
  panToMarker(coords: any, pan: boolean) {
    if (!coords) {
      return
    }

    if (pan) {
      this.map.panTo(coords)
    }
  }

  zoomToMarker() {
    if (this.zoomOnMove) {
      const zoom = typeof this.zoomOnMove === 'number' ? this.zoomOnMove : 18
      this.map.setZoom(zoom)
    }
  }

  /**
   * Reset marker
   */
  reset() {
    if (this.marker) {
      this.marker.setMap(null)
    }

    this.clearCircle()
  }

  /**
   * Remove marker from layout
   */
  removeMarker() {
    this.reset()
    this.marker = null
    this.clearCircle()
  }

  /**
   * Trigger hover action
   */
  hoverElement() {
    this.data.active = !this.data.active
  }

  /**
   * Refresh marker by removing the marker and redrawing it
   */
  refreshMarker() {
    this.reset()
    this.initialize()
  }

  /**
   * Create new marker with their properties
   */
  private initialize() {
    const location: any = this.getDefaultCoords()
    if (isEmpty(location) || !location.lat || !location.lng) {
      return
    }

    const coords = new google.maps.LatLng(this.location.lat, this.location.lng)

    const options = {
      ...this.data,
      location: coords,
    }

    if (options.className) {
      this.styleClass = options.className
    }

    const markerOptions = this.generateMarkerOptions(options)
    this.latLng = coords
    this.marker = new CustomMarker(
      coords,
      this.map,
      this.getMarkerLayout(),
      markerOptions
    )
    this.updateCircle(this.location)

    const bounds = new google.maps.LatLngBounds()
    bounds.extend(coords)

    if (!this.loaded && !isRedesign()) {
      // this.loaded = true
      setTimeout(() => this.zoomToMarker(), 0)
    }

    setTimeout(() => this.panToMarker(coords, this.panOnPlace), 200)
  }

  private clearCircle(): void {
    if (this.circle) {
      this.circle.setMap(null)
    }
  }

  private async updateCircle(location: GoogleLocationMarker): Promise<void> {
    this.clearCircle()

    if (location && location.accuracy) {
      const coords = new google.maps.LatLng(location.lat, location.lng)

      this.circle = new google.maps.Circle({
        center: coords,
        radius: location.accuracy,
        map: this.map,
        fillColor: '#4285f4',
        fillOpacity: 0.1,
        strokeWeight: 1,
        strokeColor: '#4285f4',
        strokeOpacity: 0.4,
      })
    }
  }

  /**
   * Generate custom marker data
   *
   * @param route route item
   */
  private generateMarkerOptions(content: any) {
    const data = clone(content)

    return {
      id: data._id,
      data,
      dragEvent: (coords) => this.markerDragged(coords),
    }
  }

  /**
   * Get marker layout from html content
   */
  private getMarkerLayout() {
    return this.customMarker.nativeElement || document.createElement('div')
  }

  /**
   * Get default coordinates
   */
  private getDefaultCoords() {
    return this.location || {}
  }
}
