import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { AAAStore } from '../../../store/root-reducer'
import { CHARACTER_LIMIT_VEHICLE_COLOR } from '../../location/location.constants'
import { Color, COLORS } from '../vehicle.utils'
import { AdobeEventTypes } from '../../tagging/tagging.types'
import events from '../../tagging/events'
import { AdobeEventService } from '../../tagging/adobe/event-adobe.service'
import { AbstractComponent } from '../../../shared/abstract.component'
import { selectEditOrAddVehicleDesc } from '../vehicle.selectors'
import { clearActiveVehicle, VEHICLE_UPDATE } from '../../member/member.actions'
import { selectIsLoading } from '../../ui/loading/loading.selectors'
import { setVehicleStep } from '../vehicle.actions'
import { VEHICLE_COLORS } from '../vehicle.contants'
import { Observable } from 'rxjs'

const COLORS_AND_OTHER = (): Color[] => [
  ...COLORS(),
  {
    label: $localize`other`,
    code: 'other',
  },
]


@Component({
  selector: 'app-color-step',
  templateUrl: './color-step.component.html',
  styleUrls: ['./color-step.component.scss'],
})
export class ColorStepComponent extends AbstractComponent implements OnInit {
  @Input() selectedColor: string
  @Output() selectedColorChange: EventEmitter<string> =
    new EventEmitter<string>()

  otherColor = 'Other'
  colorRows = COLORS_AND_OTHER()
  maxTabIndex = COLORS_AND_OTHER.length + 1;

  maxChars = CHARACTER_LIMIT_VEHICLE_COLOR

  editOrAddVehicleDesc$ = this.store$.pipe(select(selectEditOrAddVehicleDesc))
  editOrAddVehicleDesc: string

  isLoading$: Observable<any> = this.store$.pipe(
    select(selectIsLoading(VEHICLE_UPDATE.ACTION))
  )

  constructor(
    private store$: Store<AAAStore>,
    private adobeEventService: AdobeEventService
  ) {
    super()
  }

  ngOnInit() {
    this.subscriptions.push(
      this.editOrAddVehicleDesc$.subscribe(
        editOrAdd => this.editOrAddVehicleDesc = editOrAdd
      )
    )
  }

  selectColor(color: string) {
    this.selectedColor = color
    this.selectedColorChange.emit(color)

    if (!VEHICLE_COLORS.find((match) => match.name === color)) {
      this.otherColor = color
    }

    this.adobeEventService.sendEvent({
      eventName: AdobeEventTypes.CTA,
      eventValue: `${this.editOrAddVehicleDesc} ${events.vehicle.VEHICLE_SELECT_COLOR}`
    })
  }

  onBack() {
    this.store$.dispatch(clearActiveVehicle())
    this.store$.dispatch(setVehicleStep({ payload: { step: '' } }))
  }

}
