import { Injectable } from '@angular/core'
import { Adapter } from '../types'
import {
  AARData,
  AarDataApiModel,
  EvStationDetails,
  EvStationDetailsApiModel,
  TowDestination
} from '../../modules/location/aar/aar.types';
import { ConnectorTypeAdapter } from './ConnectorTypeAdapter';

@Injectable({
  providedIn: 'root',
})
export class AARDataAdapter implements Adapter<AARData> {

  constructor(
    private connectorTypeAdapter: ConnectorTypeAdapter,
  ) {
  }

  adapt(data: AarDataApiModel): AARData {
    return {
      ...data,
      evStationDetails: this.getEvStationDetails(data.evStationDetails)
    }
  }

  private getEvStationDetails(apiModel: EvStationDetailsApiModel): EvStationDetails {
    return apiModel
      ? {
        ...apiModel,
        connectorTypes: apiModel.connectorTypes?.map(ct => this.connectorTypeAdapter.adapt(ct)),
      }
      : null
  }

  adaptTowDestination(data: TowDestination): AARData {
    return {
      id: Number(data.details.id),
      isDiscountAvailable: data.details.isDiscountAvailable,
      longitude: data.coordinates.longitude,
      latitude: data.coordinates.latitude,
      distanceTo: data.distance,
      type: data.towDestinationType.toString(),
      name: data.details.name,
      serviceProviderCode: data.details.serviceProviderCode,
      address: {
        addressLine: data.details.streetAddress,
        cityName: data.details.city,
        stateProv: {
          code: data.details.state,
        },
        postalCode: data.details.postalCode,
        countryName: {
          value: data.details.country,
          code: data.details.country,
        },
        type: 'PHYSICAL',
      },
      phone: data.details.phones?.[0],
      vehicles: data.details.makes?.map(make => ({ value: make })),
      serviceOffers: data.details.services?.map(service => service.text),
      operatingDays: data.details.operatingDays?.map(operatingDay => ({
        day: operatingDay.day,
        close: operatingDay.shifts?.[0].close,
        open: operatingDay.shifts?.[0].open,
      })),
      ratingSummary: data.details.ratingSummary,
      emailAddress: data.details.email,
      webAddress: data.details.webAddress,
      priority: data.details.priority,
    }
  }

}
